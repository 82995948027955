@import 'assets/css/variables.scss';
@import 'assets/css/global.scss';
@import 'assets//css/mixins';
@import 'assets/css/fonts.scss';
@import 'components/components.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%; // 1rem = 10px; 10px/16px = 62.5%

  @include respond(tab-land) {
    font-size: 56.25%; // 1rem = 9px; 8px/16px = 56.25%
  }
  @include respond(tab-port) {
    font-size: 50%; // 1rem = 8px; 8px/16px = 50%
  }
  @include respond(phone) {
    font-size: 46.87%; // 1rem = 7.5px; 7.5px/16px = 46.87%
  }
}

body {
  font-size: 1.4rem;
  box-sizing: border-box;
  font-family: $font-regular, sans-serif;
  color: $gray10;
}

#root {
  min-height: 100vh;
  overflow-x: hidden;
}
