/*
// MEDIA QUERY BREAKPOINTS MANAGER
//* 0 - 600px:      Phone
//* 600 - 900px:    Tablet Portrait
//* 600 - 992px:    Tablet Portrait Bootstrap
//* 992 - 1200px:   Tablet Landscape
//* 1200 - 1500px:  Desktop - our base style
//* 1500 - 1800px:  Normal Desktop
//* 1800px + :      Big desktop

$breakpoint param choices:
- phone
- tab-port
- tab-port-bs
- tab-land
- normal-land
- big-desktop

1em = 16px


// Usage
@include respond(tab-port) {
  width: 80%;
  ...
}
@include respond(phone) {
  width: 100%;
  ...
}
*/
@mixin respond($breakpoint) {
  @if $breakpoint == phone-m {
    @media (max-width: 20em) {
      // 600px
      @content;
    }
  }
  @if $breakpoint == phone {
    @media (max-width: 37.5em) {
      // 600px
      @content;
    }
  }
  @if $breakpoint == tab-port {
    @media (max-width: 56.25em) {
      // 900px
      @content;
    }
  }
  @if $breakpoint == tab-port-bs {
    @media (max-width: 62em) {
      // 992px
      @content;
    }
  }
  @if $breakpoint == tab-land {
    @media (max-width: 75em) {
      // 1200px
      @content;
    }
  }
  @if $breakpoint == normal-desktop {
    @media (max-width: 93.75em) {
      // 1500px
      @content;
    }
  }
  @if $breakpoint == big-desktop {
    @media (min-width: 112.5em) {
      // 1800px
      @content;
    }
  }
}
