// Colors
$darkBlue5: #919bb4;
$darkBlue10: #233869;
$green: #5fca9a;
$green10: #1bb36e;
$gray: #e5e5e5;
$gray2: #eaeaea;
$gray10: #1e1e1e;
$lightBlack: #4a4a4a;
$black: #000000;
$white: #ffffff;

// Fonts
$font-light: 'GothamRounded-Light';
$font-light-italic: 'GothamRounded-LightItalic';
$font-regular: 'GothamRounded-Book';
$font-regular-italic: 'GothamRounded-BookItalic';
$font-medium: 'GothamRounded-Medium';
$font-medium-italic: 'GothamRounded-MediumItalic';
$font-bold: 'GothamRounded-Bold';
$font-bold-italic: 'GothamRounded-BoldItalic';
$font-secondary-light: 'Nunito-Light';
$font-secondary-regular: 'Nunito-Regular';
$font-secondary-bold: 'Nunito-Bold';
$font-secondary-black: 'Nunito-Black';
$font-tertiary: 'Impact';
