@import './variables';

.font-bold {
  font-family: $font-bold;
}

.font-medium {
  font-family: $font-medium;
}

.font-bold {
  font-family: $font-bold;
}

.full-height {
  min-height: 100%;
}

.no-select {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
}

.ReactModal__Overlay--after-open {
  z-index: 2;
}
