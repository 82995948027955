@import 'assets/css/variables';
@import 'assets/css/mixins';

.images-container {
  display: flex;
  align-items: center;
  @include respond(tab-land) {
    justify-content: center;
  }
  .image-upload-section {
    margin-top: 5rem;
    margin-bottom: 5rem;
    .switch-button {
      cursor: pointer;
      display: flex;
      justify-content: center;
      margin-top: 1.3rem;
      span {
        color: $darkBlue10;
        font-size: 1.6rem;
        text-decoration: underline;
        font-family: $font-medium;
        margin-left: 1rem;
      }
    }
    @include respond(tab-land) {
      margin-top: 0rem;
      margin-bottom: 5rem;
    }
  }
}

.birds-section,
.meme-configurator {
  margin-top: 48px;
  p {
    font-size: 2.5rem;
    font-family: $font-medium;
    text-align: center;
  }
}

.buttons {
  button {
    &:first-child {
      width: 60%;
    }
    &:last-child {
      width: 37%;
    }
    @include respond(tab-land) {
      &:first-child {
        width: 100%;
        margin-bottom: 1rem;
      }
      &:last-child {
        width: 100%;
      }
    }
  }
  &--symmetric {
    display: flex;
    justify-content: space-between;
    button {
      &:first-child {
        width: 50%;
      }
      &:last-child {
        width: 50%;
      }
    }
    @include respond(tab-land) {
      flex-direction: column;
      button {
        @include respond(tab-land) {
          &:first-child {
            width: 100%;
            margin-bottom: 1rem;
          }
          &:last-child {
            width: 100%;
          }
        }
      }
    }
  }
}

.custom-slider {
  .rc-slider-rail {
    background-color: $gray2;
    height: 3px;
  }
  .rc-slider-handle {
    width: 2.4rem;
    height: 2.4rem;
    margin-top: -1rem;
    border: 3px solid #1bb36e;
    &-dragging {
      border-color: $green10 !important;
      box-shadow: 0 0 0 5px $green !important;
    }
  }
}

.home-subtitle-2 {
  font-family: $font-bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 3.5rem;
  margin-top: 2rem;
  font-size: 2.4rem;
  text-align: center;
  align-self: center;
  font-weight: bold;
  @include respond(phone) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.birds-section {
  p {
    @include respond(phone-m) {
      margin-left: 0px;
    }
  }
}

.download-and-share {
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.previous-memes-section {
  margin-top: 3rem !important;
  .home-subtitle-2 {
    max-width: 560px;
    margin: auto;
  }
}
