.submissions {
  margin-bottom: 6rem;
  padding-bottom: 6rem;
  width: 100%;
  margin-top: 6rem;
  padding-top: 6rem;
  text-align: center;
}

.swiper {
  overflow: visible;
  width: 100%;
  display: block;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  align-items: stretch;
}

.swiper-slide {
  height: auto;
  width: 385px;
  flex-shrink: 0;
  position: relative;
  transition-property: transform;
  transition: all 200ms ease;
  -webkit-box-direction: normal;
}

.single-submission-wrap {
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 240px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  transition: all 200ms ease;
}

.single-submission {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 133.33%;
  border-radius: 20px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.single-submission-image {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: #091217;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  content: none;
}
.swiper-button-next.hidden,
.swiper-button-prev.hidden {
  display: none;
}
.swiper-button-next,
.swiper-button-prev {
  width: 48px;
  height: 48px;
  top: calc(50% - 24px);
  margin: 0;
}
.swiper-button-next {
  right: 0;
}
.swiper-button-prev {
  left: 0;
}
.swiper-slide {
  height: auto;
  width: 385px;
}
.swiper-slide.swiper-slide-next > div,
.swiper-slide.swiper-slide-prev > div {
  transform: scale(1.17);
}
.swiper-slide.swiper-slide-active > div {
  transform: scale(1.5) rotate(-3deg);
}
.single-submission::after {
  transition: opacity 0.2s ease;
  opacity: 0;
  z-index: 1;
}
.swiper-slide.swiper-slide-active > div::after {
  display: block;
  content: '';
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    92.78% 92.78% at 50% 50%,
    #9b9b9b 0%,
    rgba(212, 163, 84, 0) 100%
  );
  filter: blur(64px);
  opacity: 1;
}
@media screen and (max-width: 767px) {
  .swiper-slide.swiper-slide-next > div,
  .swiper-slide.swiper-slide-prev > div {
    transform: scale(1);
  }
  .swiper-slide.swiper-slide-active > div {
    transform: scale(1.4) rotate(-3deg);
  }
}

.arrow-rotate {
  transform: rotate(180deg);
}

.button-3,
.button-3:hover {
  display: inline-block;
  padding: 14px 32.5px;
  border-radius: 10px;
  background-color: #5fca9a;
  font-weight: 700;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  margin-top: 20px;
}
