@font-face {
  font-family: 'GothamRounded-Light';
  src: url('../fonts/gotham-rounded/GothamRnd-Light.otf') format('opentype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'GothamRounded-LightItalic';
  src: url('../fonts/gotham-rounded/GothamRnd-LightIta.otf') format('opentype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'GothamRounded-Book';
  src: url('../fonts/gotham-rounded/GothamRnd-Book.otf') format('opentype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'GothamRounded-BookItalic';
  src: url('../fonts/gotham-rounded/GothamRnd-BookIta.otf') format('opentype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'GothamRounded-Medium';
  src: url('../fonts/gotham-rounded/GothamRnd-Medium.otf') format('opentype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'GothamRounded-MediumItalic';
  src: url('../fonts/gotham-rounded/GothamRnd-MedIta.otf') format('opentype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'GothamRounded-Bold';
  src: url('../fonts/gotham-rounded/GothamRnd-Bold.otf') format('opentype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'GothamRounded-BoldItalic';
  src: url('../fonts/gotham-rounded/GothamRnd-BoldIta.otf') format('opentype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito-Light';
  src: url('../fonts/nunito/Nunito-Light.otf') format('opentype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito-Regular';
  src: url('../fonts/nunito/Nunito-Regular.otf') format('opentype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito-Bold';
  src: url('../fonts/nunito/Nunito-Bold.otf') format('opentype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito-Black';
  src: url('../fonts/nunito/Nunito-Black.otf') format('opentype');
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Impact';
  src: url('../fonts/impact/impact.otf') format('opentype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
